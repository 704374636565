<template>
  <div class="site-header" :class="{ scrolled }">
    <h1>
      <router-link to="/">Jolande Venema</router-link>
    </h1>

    <nav>
      <ul>
        <li><router-link to="/work">Work</router-link></li>
        <li><router-link to="/artist">Artist</router-link></li>
        <li>
          <a
            href="https://instagram.com/jolandevenema"
            target="blank"
            rel="noopener"
            >Instagram</a
          >
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scrolled: false,
    };
  },
  mounted() {
    document.addEventListener("scroll", this.onScroll.bind(this), {
      passive: true,
    });
  },
  methods: {
    onScroll() {
      this.scrolled = window.scrollY > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "~@/variables";

.site-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  z-index: 1;
  text-transform: uppercase;
  font-size: 18px;
  font-family: "Josefin Sans", sans-serif;
  background-color: #f4f2e6dd;
  backdrop-filter: blur(4px);
  border-bottom: 1px solid transparent;
  transition: border-bottom-color 200ms linear;

  padding: $padding - 10px;

  @media (max-width: 600px) {
    padding: ($padding / 2) - 10px;
  }

  &.scrolled {
    border-bottom-color: black;
  }
}

h1,
nav {
  animation: 500ms cubic-bezier(1, 0, 1, 1) 500ms both fade-in;
}

h1 {
  margin: 0;
  margin-right: 50px;
  font-size: inherit;
  flex: none;
}

ul {
  list-style: none;
  display: flex;
  margin: 0;
  padding: 0;

  a {
    transition: transform 300ms ease;

    &:hover {
      transform: translateY(-4px);
    }
  }
}

a {
  display: block;
  padding: 10px;
  color: inherit;
  text-decoration: none;
}
</style>
