<template>
  <page-wrapper class="home">
    <img alt="Logo image (girl with halo)" src="../assets/logo.png" />
  </page-wrapper>
</template>

<script>
import PageWrapper from "@/components/PageWrapper";

export default {
  metaInfo: {
    title: "Jolande Venema",
    titleTemplate: null,
  },
  components: {
    PageWrapper,
  },
};
</script>

<style lang="scss" scoped>
.home {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

img {
  display: block;
  width: 100%;
  max-width: 350px;
  animation: 500ms cubic-bezier(1, 0, 1, 1) both fade-in;
}
</style>
