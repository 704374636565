<template>
  <div id="app">
    <site-header />
    <router-view />
  </div>
</template>

<script>
import SiteHeader from "./components/SiteHeader";

export default {
  metaInfo: {
    titleTemplate: "%s | Jolande Venema",
  },
  components: {
    SiteHeader,
  },
};
</script>

<style lang="scss">
// Color: https://coolors.co/000000-ffffff-f4f2e6-393d2f-e83535

*,
::before,
::after {
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  margin: 0;
  min-height: 100%;
  background-color: #f4f2e6;
}

body,
#app {
  display: flex;
  flex-direction: column;
}

#app {
  font-family: "EB Garamond", sans-serif;
  font-size: 21px;
  color: #173309;

  flex-grow: 1;
  > * {
    flex-grow: 1;
  }
}

h1,
h2,
p,
blockquote,
hr {
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

h1,
h2,
h3 {
  text-transform: uppercase;
  font-family: "Josefin Sans", sans-serif;
}

* + h1,
* + h2 {
  margin-top: 4em;
}

@media (max-width: 500px) {
  h1 {
    font-size: 1.8em;
  }
}

@media (max-width: 400px) {
  h1 {
    font-size: 1.5em;
  }

  h2 {
    font-size: 1.3em;
  }
}

a {
  color: #5b754f;
  outline: none;

  [data-whatinput="keyboard"] &:focus {
    background: rgba(0, 0, 0, 0.1);
  }
}

blockquote {
  font-size: 2em;
  margin-top: 2em;
  margin-bottom: 2em;
  @media (max-width: 680px) {
    font-size: 6.5vw;
  }
}

hr {
  margin-top: 2em;
  margin-bottom: 2em;
  height: 0;
  border: none;
  border-bottom: 1px solid currentColor;
}

@keyframes fade-in {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
