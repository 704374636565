import Vue from "vue";
import Router from "vue-router";
import Meta from "vue-meta";
import Home from "./views/Home.vue";

Vue.use(Router);
Vue.use(Meta);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
    },
    {
      path: "/artist",
      name: "artist",
      component: () =>
        import(/* webpackChunkName: "artist" */ "./views/Artist.vue"),
    },
    {
      path: "/work",
      name: "work",
      component: () =>
        import(/* webpackChunkName: "work" */ "./views/Work.vue"),
    },
    {
      path: "/work/:id",
      name: "work-item",
      component: () =>
        import(/* webpackChunkName: "work-item" */ "./views/WorkItem.vue"),
    },
    {
      path: "/algemene-voorwaarden",
      name: "algemene-voorwaarden",
      component: () => import(/* webpackChunkName: "AV" */ "./views/AV.vue"),
    },
  ],
});
